import { Image } from "antd";
import React, { useState } from "react";

const ImageViewer = ({ src, style, className }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Image
        preview={{
          visible: false,
        }}
        // width={"400px"}
        src={src}
        onClick={() => setVisible(true)}
        style={style}
        className={className}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}
        >
          <Image src={src} />
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default ImageViewer;
