import { PERMISSIONS, USER_ROLES } from "components/common/Constants";
import JobCardForm from "components/jobcard/JobcardForm";
import { roleSelector } from "features/apis/jobCardApi";
import { useCreateJobcardMutation, useGetDropdownsQuery } from "features/store";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreateJobcard = () => {
  const role = useSelector(roleSelector);
  if (!PERMISSIONS.CREATE.includes(role)) return <>NOT ALLOWED</>;

  return <JobCardForm />;
};

export default CreateJobcard;
