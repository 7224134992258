import { Button } from "antd";
import JobcardListing from "components/jobcard/JobcardListing";
import { PERMISSIONS, USER_ROLES } from "../components/common/Constants";
import { roleSelector } from "features/apis/jobCardApi";
import {
  useFetchLocationCountQuery,
  useFetchGetLocationCountQuery,
  useFetchJobcardsQuery,
  useGetCardsByLocationQuery,
  useGetCardsUnderApprovalQuery,
  useGetClientsQuery,
  useFetchJobcardStageCountQuery,
} from "features/store";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loader from "components/loaders/Loader";
import {
  getCardsApprovedByClients,
  getCardsForApproval,
  getCardsInAccount,
  getCardsInCreation,
  getCardsInFinishing,
  getCardsInProduction,
  getCardsRejectedByClients,
  getCompletedJobCards,
} from "helpers/jobcardHelper";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ScissorOutlined,
  CloseCircleOutlined,
  DashboardOutlined,
  FileOutlined,
  BookOutlined,
  SmileOutlined,
  AccountBookOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { CountPieChart } from "components/Stats/CountPie";
import CountPieChartT from "components/Stats/CountPieChartT";
import CountChrt from "components/Stats/CountChrt";
import AllLocationChart from "components/Stats/AllLocationChart";
import CardItemQuantityChart from "components/Stats/CardItemQuantityChart";
import CountperLocation from "components/jobcard/CountperLocation";

const Dashboard = () => {
  const role = useSelector(roleSelector);
  const { data: jobCards, isLoading: jobcardsloading } =
    useFetchJobcardsQuery();

  const { data: locationcards, isLoading: locloading } =
    useFetchLocationCountQuery();

  const { data: cardsstagecount, isLoading: stageloading } =
    useFetchJobcardStageCountQuery();

  if (role == USER_ROLES.CLIENT || role == USER_ROLES.SUPERVISOR)
    return <Navigate to="/jobcards" />;

  if (locloading) return <Loader />;

  if (stageloading) {
    return <h1 style={{ textAlign: "center" }}>Loading...... </h1>;
  }

  if (!PERMISSIONS.SHOW_DASHBOARD.includes(role))
    return <Navigate to={"jobcards"} />;

  const styleAndIconMapper = (name) => {
    switch (name) {
      case "Creation":
        return { icon: <DashboardOutlined />, style: { color: "#1890ff" } };
      case "Approval":
        return { icon: <CheckCircleOutlined />, style: { color: "green" } };
      case "is_approved":
        return { icon: <SmileOutlined />, style: { color: "green" } };
      case "is_rejected":
        return { icon: <CloseCircleOutlined />, style: { color: "red" } };
      case "Production":
        return { icon: <ScissorOutlined />, style: { color: "purple" } };
      case "Finishing":
        return { icon: <ScissorOutlined />, style: { color: "violet" } };
      case "Account":
        return { icon: <BookOutlined />, style: { color: "orange" } };
      case "Completed":
        return { icon: <FileDoneOutlined />, style: { color: "blue" } };
      case "total jobcards":
        return { icon: <FileOutlined />, style: { color: "grey" } };
      default:
        return { icon: <FileOutlined />, style: { color: "black" } }; // Default case
    }
  };
  return (
    <Style>
      <div className="count-card-section">
        <Row className="card-counts" gutter={10}>
          {cardsstagecount.map((item) => (
            <Col>
              <Card>
                <Statistic
                  title={item.name}
                  value={item.value}
                  valueStyle={styleAndIconMapper(item.name).style}
                  prefix={styleAndIconMapper(item.name).icon}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <ChartStyle className="pichart">
        <div className="histogram">
          <div className="all-data-carts">
            <CountPieChart jobcards={cardsstagecount} />
            {/* <CountPieChart jobcards={jobCards} /> */}
            {/* <CardItemQuantityChart  /> */}
          </div>
          <hr />
          <h1>Location wise Job Card Status</h1>
          <CountperLocation
            locationcards={locationcards}
            locloading={locloading}
          />
        </div>
        <AllLocationChart />
      </ChartStyle>
    </Style>
  );
};

const Style = styled.div`
  .count-card-section {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    align-items: center;
    place-items: center;
    margin: 1rem auto;
  }
  .all-data-carts {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    justify-content: center;
    align-items: center;
    place-items: center;
    margin: 1rem auto;
  }
  .card-counts {
    margin: 0 0 1rem 0;
    width: fit-content;
    .ant-card {
      transition: all 200ms linear;
      box-shadow: 0px 0px 2px var(--color-primary);
      :hover {
        transform: scale(1.1);
      }
    }
  }
`;

export default Dashboard;

const ChartStyle = styled.div`
  .histogram {
    margin: 0 2rem;
  }
  .LegendList {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    list-style: none; /* Remove default bullets */
    width: 500px;
  }

  .LegendList li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .BulletLabel {
    display: flex;
    align-items: center;
  }

  .CirecleBullet {
    border-radius: 50%;
  }

  .BulletLabelText {
    margin-left: 10px;
  }

  .CenteredLabelValue {
    font-size: 36px;
    font-weight: 600;
    fill: #0088fe;
  }
  @media screen and (max-width: 458px) {
    .histogram {
      margin: 0 1rem;
    }
  }
`;
