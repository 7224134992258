import React from 'react'
import { Pie } from "@ant-design/plots";
import { STAGES_LIST } from "components/common/Constants";
import { getJobcardsForStage } from "helpers/jobcardHelper";
import styled from "styled-components";

const MumbaiChart = ({cards}) => {
  const filteredData = cards?.filter(item => item?.name !== "is_approved" && item?.name !== "is_rejected");
    const config = {
        appendPadding: 10,
        data: filteredData,
        angleField: "value",
        colorField: "name",
        radius: 0.40,
        label: {
          type: "outer",
          labelHeight: 28,
          content: "{name}\n{value}",
        },
        legend: {
          layout: 'horizontal',
          position: 'left',
          flipPage: false
        },
        interactions: [
          {
            type: "element-selected",
          },
          {
            type: "element-active",
          },
        ],
      };
      return (
        <Style>
        <h2>Mumbai Job Card Status</h2>
          <Pie {...config} />
        </Style>
      );
    };
  
    const Style = styled.div`
      width: 450px;
       height: 400px;
      h2{
        text-align: center;
       }
    `;

export default MumbaiChart