import React from "react";
import LocationFilter from "./filterButtons/LocationFilter";
import ClientFilter from "./filterButtons/ClientFilter";
import StageFilter from "./filterButtons/StageFilter";
import "./filterButtons/filter.css";
import { useSelector } from "react-redux";
const FIlterComponents = () => {
  const { user, userToken, loading, checkAuthLoading } = useSelector(
    (state) => state.user
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "70%",
        margin: "20px auto",
      }}
    >
      {user?.role === "admin" ? <LocationFilter /> : null}
      {user?.role === "client" ? null : <ClientFilter />}
      {user?.role === "admin" ||
      user?.role === "account" ||
      user?.role === "manufacturer" ? (
        <StageFilter />
      ) : null}
    </div>
  );
};

export default FIlterComponents;
