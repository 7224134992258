import "./App.css";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Login from "pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "components/header/Header";
import { checkAuth } from "features/thunks/FetchUsers";
import "antd/dist/antd.css";
import Dashboard from "pages/Dashboard";
import JobcardDetails from "pages/JobcardDetails";
import UpdateJobcard from "pages/UpdateJobcard";
import CreateJobcard from "pages/CreateJobcard";
import Auth from "components/molecules/Auth";
import Jobcards from "pages/Jobcards";
import ResetPasswordConfirm from "pages/ResetPasswordConfirm";
import Reports from "pages/Reports";
import Privacy from "pages/Privacy";
import Footer from "components/footer/Footer";

export const AppContext = createContext();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  const [currentTablePage, setCurrentTablePage] = useState();

  return (
    <AppContext.Provider value={{ currentTablePage, setCurrentTablePage }}>
      <div className="App">
        <>
          <BrowserRouter>
            <Header />
            <div style={{ minHeight: "calc(100vh - 80px)" }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route
                  path="/"
                  element={
                    <Auth>
                      <Dashboard />
                    </Auth>
                  }
                />
                <Route
                  path="/jobcards/tab/:tab"
                  element={
                    <Auth>
                      <Jobcards />
                    </Auth>
                  }
                />
                <Route
                  path="/jobcards/tab"
                  element={
                    <Auth>
                      <Jobcards />
                    </Auth>
                  }
                />
                <Route
                  path="/jobcards"
                  element={
                    <Auth>
                      <Jobcards />
                    </Auth>
                  }
                />

                <Route
                  path="/update/:id"
                  element={
                    <Auth>
                      <UpdateJobcard />
                    </Auth>
                  }
                />
                <Route
                  path="/create"
                  element={
                    <Auth>
                      <CreateJobcard />
                    </Auth>
                  }
                />
                <Route
                  path="/detail/:id"
                  element={
                    <Auth>
                      <JobcardDetails />
                    </Auth>
                  }
                />
                <Route
                  path="/report"
                  element={
                    <Auth>
                      <Reports />
                    </Auth>
                  }
                />
                <Route
                  path="/auth/users/reset_password_confirm/:uid/:token"
                  element={<ResetPasswordConfirm />}
                />
              </Routes>
            </div>
            <Footer />
          </BrowserRouter>
        </>
      </div>
    </AppContext.Provider>
  );
}

export default App;
