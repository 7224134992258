import { Steps } from "antd";
import { STAGES } from "components/common/Constants";
import React from "react";
import styled from "styled-components";

function JobcardStep({ current_stage }) {
  console.log(current_stage);
  return (
    <Style>
      <Steps
        progressDot
        current={Object.values(STAGES).indexOf(current_stage)}
        direction="vertical"
        items={Object.values(STAGES).map((stage) => ({
          title: stage.toUpperCase(),
        }))}
      />
    </Style>
  );
}

const Style = styled.div`
  padding: 2rem 4rem;
  width: fit-content;
`;

export default JobcardStep;
