import { Button, message, Space, Table, Tag } from "antd";
import {
  USER_ROLES,
  STAGES,
  STAGES_LIST,
  PERMISSIONS,
} from "../common/Constants";
import {
  getFormalName,
  getNextStage,
  getPreviousStage,
} from "../common/Services";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  roleSelector,
  useUpdateJobcardMutation,
} from "features/apis/jobCardApi";
import Loader from "components/loaders/Loader";
import styled from "styled-components";
import { useContext } from "react";
import { AppContext } from "App";

function JobcardsTableClinet({ cards, clients, isCompleted, tableKey }) {
  const { currentTablePage, setCurrentTablePage } = useContext(AppContext);

  const role = useSelector(roleSelector);

  const [client, setClient] = useState();

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setClientSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "client_company",
    });
  };

  const client_namesss = [...new Set(cards.map((card) => card.client.name))];

  //

  const clientSet = new Set(cards?.map((item) => item.client.name));
  const locationSet = new Set(cards?.map((item) => item.location.location));
  //

  const dataSource = cards.map((card) => ({
    ...card,
    client_company: card.Party_name,
    current_stage:
      card.current_stage == null ? STAGES.CREATION : card.current_stage,
  }));

  const [tableColumns, setTableColumns] = React.useState();
  const navigate = useNavigate();
  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();

  const { isLoading: isUpdating } = updateResponseInfo;

  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    {
      title: "Client Company",
      dataIndex: ["client", "name"],
      key: "client",
      // filteredValue: filteredInfo.client_company || null,
      filters: [...clientSet].map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => record.client.name?.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Location",
      dataIndex: ["location", "location"],
      key: "client",
      // filteredValue: filteredInfo.client_company || null,
      filters: [...locationSet].map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) =>
        record.location.location?.indexOf(value) === 0,
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      sorter: (a, b) =>
        moment(a.Date, "DD/MM/YYYY").unix() -
        moment(b.Date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Job Out Time",
      dataIndex: "job_out_field",
      key: "Date",
      sorter: (a, b) =>
        moment(a.Date, "DD/MM/YYYY").unix() -
        moment(b.Date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Last Updated By",
      dataIndex: "Last_update",
      key: "Last_update",
    },
    // {
    //   title: "Last Updated By",
    //   dataIndex: "location",
    //   key: "location",
    // },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "current_stage",
      key: "current_stage",
      filters: STAGES_LIST.map((stage) => ({
        text: getFormalName(stage),
        value: stage,
      })),
      onFilter: (value, record) => record.current_stage.indexOf(value) === 0,
      render: (text, record) => {
        return getFormalName(text);
      },
    },
    {
      title: "Client Status",
      dataIndex: "is_rejected",
      key: "client_status",
      render: (text, record) => {
        return (
          <>
            {record.is_rejected && <Tag color="red">Rejected</Tag>}
            {record.is_approved && <Tag color="green">Approved</Tag>}
          </> //
        );
      },
    },
    {
      title: "Update",

      key: "update",
      render: (_, record) =>
        [STAGES.CREATION].includes(record.current_stage) && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/update/${record.id}`);
            }}
          >
            Update
          </Button>
        ),
    },
    {
      title: "Review",

      key: "review",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            updateJobcard({
              id: record.id,
              current_stage: getPreviousStage(record.current_stage),
            });
          }}
          disabled={getPreviousStage(record.current_stage) === "-"}
        >
          {record.current_stage === STAGES.COMPLETED
            ? "Completed"
            : `Send back to ${getFormalName(
                getPreviousStage(record.current_stage)
              )}`}
        </Button>
      ),
    },
    {
      title: "Forward",
      key: "forward",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();

            updateJobcard({
              id: record.id,
              client: record.client.id,
              current_stage: getNextStage(record.current_stage),
            });
          }}
          disabled={getNextStage(record.current_stage) === "-"}
        >
          {record.current_stage === STAGES.COMPLETED
            ? "Completed"
            : `${
                record.current_stage === STAGES.ACCOUNT
                  ? "Mark as"
                  : "Forward to"
              }  ${getFormalName(getNextStage(record.current_stage))}`}
        </Button>
      ),
    },
  ]; //

  useEffect(() => {
    let _table_columns = columns;

    if (!PERMISSIONS.UPDATE.includes(role))
      _table_columns = _table_columns.filter(
        (column) => column.title !== "Update"
      );

    if ([USER_ROLES.CLIENT].includes(role))
      _table_columns = _table_columns.filter(
        (column) =>
          column.title !== "Review" &&
          column.title !== "Forward" &&
          column.title !== "Client Status" &&
          column.title !== "Last Updated By" &&
          column.title !== "Current Stage" &&
          column.title !== "Client Company"
      );

    setTableColumns(_table_columns);
  }, [role, clientSet, locationSet]);

  return (
    <>
      {isUpdating && <Loader />}
      {/*   <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={setClientSort}>Sort Company</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space> */}
      {/* {console.log(dataSource)} */}
      <Table
        style={{ width: "100%" }}
        dataSource={dataSource}
        columns={tableColumns}
        rowClassName={"table-row"}
        onChange={handleChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/detail/${record.id}`);
            },
            // className: record.current_stage,
            className: `priority-${record.priority}`,
          };
        }}
        pagination={{
          pageSize: 15,
          defaultCurrent: currentTablePage?.[tableKey],
          onChange: (page, pageSize) =>
            setCurrentTablePage((prev) => ({ ...prev, [tableKey]: page })),
        }}
      />
    </> //
  );
}

export default JobcardsTableClinet;
