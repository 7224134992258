import { STAGES_LIST } from "components/common/Constants";
import { Card } from "antd";

import {
  getJobcardsForStage,
  getJobcardsForStageVas,
} from "helpers/jobcardHelper";
import React from "react";
import { Pie } from "@ant-design/plots";
import { CountPieChart } from "./CountPie";
import VasaiPieChart from "./VasaiPieChart";
import MumbaiChart from "./MumbaiChart";
import SwargatePieChart from "./SwargatePieChart";
import WarjePieChart from "./WarjePieChart";
import styled from "styled-components";
import CountperLOcation from "components/jobcard/CountperLocation";
import { useFetchJobcardStageCountQuery, useFetchLocationCountQuery } from "features/store";

const AllLocationChart = () => {
  
  // const { data: mumbai}=useFetchJobcardStageCountQuery({location: "mumbai"})
  // 
  const { data: vasai, isLoading}=useFetchJobcardStageCountQuery({location:1})
  
  const { data: swargate, isLoading: swrloading}=useFetchJobcardStageCountQuery({location:2})
  
  const { data: warje, isLoading:warjeloading}=useFetchJobcardStageCountQuery({location:3})
  
  const { data: mumbai, isLoading:mumloading}=useFetchJobcardStageCountQuery({location:4})
  

  if (isLoading)  return <div>loading...</div>;
  if (swrloading)  return <div>loading...</div>;
  if (warjeloading)  return <div>loading...</div>;
  if (mumloading)  return <div>loading...</div>;
  

  
  return (
    <>
      <Style>
      
        <div class="all-align">
          <div className="charts">
            <VasaiPieChart cards={vasai} />
          </div>
          <div className="charts">
            <MumbaiChart cards={mumbai} />
          </div>
          <div className="charts">
            <SwargatePieChart cards={swargate} />
          </div>
          <div className="charts">
            <WarjePieChart cards={warje} />
          </div>
        </div>
      </Style>
    </>//
  );
};

const Style = styled.div`
  .all-align {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 1rem;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin: 0 2rem;
    padding: 0 2rem;
    // margin-right: auto;
    // border: 2px dashed rgba(114, 186, 94, 0.35);
    .charts {
      border: 2px dashed rgba(114, 186, 94, 0.2);
    }
  }
  @media screen and (max-width: 458px) {
    .all-align{
      padding: 0.5rem;
      margin: 1rem 0.5rem;
    }
  }
`;

export default AllLocationChart;
