import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import styled from "styled-components";
import { getJobcardsForStage } from "helpers/jobcardHelper";
import { STAGES_LIST } from "components/common/Constants";

const CardItemQuantityChart = ({ locationcards }) => {
  let data = [ ]

  for(let card of locationcards)
  {
    data.push( { date: card?.Date, category: "Rejected Quantity", value: card?.Rejected_quantity  }, { date: card?.Date, category: "Total Quantity", value: card?.Quantity  } , { date: card?.Date, category: "Finished Quantity", value: card?.Finished_quantity  }  )
}

  console.log("qty", data);
  const COLOR_PLATE_10 = [
    "#E8684A",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
  ];
  const container = document.getElementById("container");
  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        // format the value as thousand
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ category }) => {
        return category === "Rejected Quantity" ? "square" : "circle";
      },
      style: ({ date }) => {
        return {
          r: Number(date) % 4 ? 0 : 3, // 4 data points represents a point mark
        };
      },
    },
  };
  return (
    <Style>
      <h2>Quantity Chart</h2>
      <div>
        <Line {...config} />
      </div>
    </Style>
  );
};

export default CardItemQuantityChart;
const Style = styled.div`
  width: 700px;
  height: 450px;
  h2 {
    text-align: center;
  }
`;
