import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "features/auth/userSlice";
import { useFetchJobcardsQuery } from "features/store";
import { jobCardApi, roleSelector } from "features/apis/jobCardApi";
import { PERMISSIONS, USER_ROLES } from "components/common/Constants";

const Navbar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { data, refetch } = useFetchJobcardsQuery();
  const role = useSelector(roleSelector);
  const navigate = useNavigate();
  // useEffect(() => {
  //   refetch();
  // }, [isSuccess]);
  function handleLogout() {
    dispatch(jobCardApi.util.resetApiState());
    dispatch(logout());
    refetch();
    // Redirect to login page
    navigate("/login");
  }
  const authLinks = (
    <>
      {PERMISSIONS.SHOW_DASHBOARD.includes(role) && (
        <li>
          <NavLink className="nav-link" to="/">
            Dashboard
          </NavLink>
        </li>
      )}
      <li>
        <NavLink className="nav-link" to="jobcards">
          Job Cards
        </NavLink>
      </li>
      <li>
        <NavLink className="nav-link" to="report">
          Report
        </NavLink>
      </li>
      <li>
        <span className="nav-link linkss" to="#!" onClick={handleLogout}>
          User: <b>{user?.user}</b>
        </span>
      </li>
      <li>
        <NavLink
          className="nav-link"
          to="#!"
          onClick={() => dispatch(logout())}>
          Logout
        </NavLink>
      </li>
    </> //
  );
  const guestLinks = (
    <>
      <li>
        <NavLink className="nav-link" to="/login">
          Login
        </NavLink>
      </li>
    </> //
  );
  return (
    <>
      <Style>
        <nav className="navbar">
          <div className="navbar-container container">
            <input type="checkbox" name="" id="" />
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <ul className="menu-items">{user ? authLinks : guestLinks}</ul>
            <h1 className="logo">Kesh Fashion</h1>
          </div>
        </nav>
      </Style>
    </> //
  );
};

export default Navbar;

const Style = styled.div`
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: none;
  }

  .container {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    position: relative;
  }

  .navbar {
    box-shadow: 0px 5px 10px 0px #aaaaaa54;
    /* position: fixed; */
    width: 100%;
    background: #fff;
    color: #000;
    /* opacity: 0.85; */
    z-index: 100;
    ul {
      margin-bottom: 0;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  ul li a {
    transition: 0.5s ease;
  }
  ul:hover li a:not(:hover) {
    color: #000;
    opacity: 0.3;
    filter: blur(1px);
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
  }

  .menu-items {
    order: 2;
    display: flex;
  }
  .logo {
    order: 1;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .menu-items li {
    list-style: none;
    margin-left: 1.5rem;
    font-size: 1.2rem;
  }

  .navbar a {
    color: #444;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }

  .navbar a:hover {
    color: var(--color-primary);
  }

  @media (max-width: 768px) {
    .navbar {
      /* opacity: 0.95; */
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }

    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }

    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }

    .navbar-container .hamburger-lines {
      display: block;
      height: 28px;
      width: 35px;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #333;
    }

    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items {
      padding-top: 100px;
      background: #fff;
      height: 100vh;
      max-width: 300px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 40px;
      transition: transform 0.5s ease-in-out;
      box-shadow: 5px 0px 10px 0px #aaa;
      overflow: scroll;
      z-index: 1;
      position: relative;
    }

    .navbar .menu-items li {
      margin-bottom: 1.8rem;
      font-size: 1.1rem;
      font-weight: 500;
    }

    .logo {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 2rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-45deg);
    }
  }

  @media (max-width: 500px) {
    .navbar-container input[type="checkbox"]:checked ~ .logo {
      display: none;
    }
  }
`;
