import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button, Form } from "antd";
import React, { useState, useRef } from "react";
const { Option } = Select;
let index = 0;

const SelectType = ({ options, name, value, label, rules }) => {
  // console.log("options", options);
  const [items, setItems] = useState(options || []);
  const [_name, setName] = useState("");
  const inputRef = useRef(null);
  const selectRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, _name || `New item ${index++}`]);
    setName("");
    // setTimeout(() => {
    //   selectRef.current.value = _name;
    // }, 0);
  };

  return (
    <Form.Item name={name} rules={rules} label={label}>
      <Select
        placeholder={`Select ${label}`}
        ref={selectRef}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder={`Please enter manual ${label}`}
                
                ref={inputRef}
                value={_name}
                onChange={onNameChange}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Add {label}
              </Button>
            </Space>
          </>
        )}
      >
        {items.map((item) => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectType;
