import React, { useRef, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { LoginStyle, Button } from "./Page.style";
import { useDispatch, useSelector } from "react-redux";

import { RotatingLines, ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import { userLogin } from "features/thunks/FetchUsers";
import { url } from "components/common/api";
import { Alert } from "antd";

const ResetPasswordConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const from = location.state?.from?.pathname || "/";
  const dispatch = useDispatch();
  const [resetError, setResetError] = useState(false);

  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const { new_password, re_new_password } = formData;

  const resetPasswordConfirm = (uid, token, new_password, re_new_password) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uid,
      token,
      new_password,
      re_new_password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}/auth/users/reset_password_confirm/`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => setResetError(error));
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setResetError(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const uid = params.uid;
    const token = params.token;
    resetPasswordConfirm(uid, token, new_password, re_new_password);
    Alert("Password reset successful");
  };

  return (
    <>
      <LoginStyle>
        <div className="login-section body">
          <div className="login-container">
            <div className="login-box">
              <h2>Login In Your Account</h2>

              {resetError && <div className="error">{resetError}</div>}

              <form className="form" onSubmit={onSubmit}>
                <div className="user-box">
                  <input
                    id="new_password"
                    label="New Password"
                    required
                    name="new_password"
                    value={new_password}
                    onChange={onChange}
                  />
                  <label>Username</label>
                </div>
                <div className="user-box">
                  <input
                    id="password"
                    type="password"
                    name="re_new_password"
                    required
                    value={re_new_password}
                    onChange={onChange}
                  />
                  <label>Password</label>
                </div>
                {/*{loading ? (
                 
                  <ThreeDots 
                  height="80" 
                  width="80" 
                  radius="9"
                  color="#7ce0ff" 
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{textAlign: 'center', alignItems: 'center',
                  justifyContent: 'center'}}
                  wrapperClassName=""
                  visible={true}
                  />
                ) : ( */}
                <div className="form-button">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LoginStyle>
      <Footer />
    </> //
  );
};

export default ResetPasswordConfirm;
