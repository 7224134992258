import { PERMISSIONS, STAGES, USER_ROLES } from "components/common/Constants";
import JobCardBetweenDates from "components/jobcard/JobCardBetweenDates";

import { roleSelector, useGetClientsQuery } from 'features/apis/jobCardApi';
import React from 'react'
import { useSelector } from 'react-redux';

const Reports = () => {
    const role = useSelector(roleSelector);
    const { data: clients } = useGetClientsQuery();
    const showReport = () =>
    [USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.ACCOUNT].includes(role);

  const showReportComp = () => showReport();
 
    return <div>{showReportComp() && (<JobCardBetweenDates clients={clients} />)}</div>;


}

export default Reports