const { STAGES } = require("components/common/Constants");

export const getCompletedJobCards = (cards) =>
  cards?.filter((card) => card.current_stage === STAGES.COMPLETED);

export const getCardsForApproval = (cards) =>
  cards?.filter((card) => card.current_stage === STAGES.APPROVAL);


export const getCardsInProduction = (cards) =>
  cards?.filter((card) => card.current_stage === STAGES.PRODUCTION);
export const getCardsInFinishing = (cards) =>
  cards?.filter((card) => card.current_stage === STAGES.FINISHING);
export const getCardsInCreation = (cards) =>
  cards?.filter((card) => card.current_stage === STAGES.CREATION);
export const getCardsInAccount = (cards) =>
  cards?.filter((card) => card.current_stage === STAGES.ACCOUNT);

export const getCardsRejectedByClients = (cards) =>
  cards?.filter((card) => card.is_rejected);
  
export const getCardsApprovedByClients = (cards) =>
  cards?.filter((card) => card.is_approved);

export const getJobcardsForStage = (jobcards, stage) =>
  jobcards?.filter((card) => card.current_stage === stage);