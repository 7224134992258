import { logout } from "features/auth/userSlice";
import { useDispatch } from "react-redux";

export const VerifyUser = (error) => {
  console.log("error: ", error);
  const dispatch = useDispatch();
  if (error) {
    if (error.status === 401) {
      dispatch(logout());
    }
  }
};
