import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import { STAGES_LIST } from "components/common/Constants";
import { getJobcardsForStage } from "helpers/jobcardHelper";
import styled from "styled-components";

export const CountPieChart = ({ jobcards }) => {

  const filteredData = jobcards?.filter(item => item?.name !== "is_approved" && item?.name !== "is_rejected" && item?.name !== "total jobcards");
  const config = {
    appendPadding: 10,
    data: filteredData,
    angleField: "value",
    colorField: "name",
    radius: 0.5,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name}\n{value}",
    },
    legend: {
      layout: "horizontal",
      position: "left",
      flipPage: false,
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  return (
    <Style>
      <h2>All Location Job Card Status</h2>
      <div className="all-location-pie" ></div>
      <Pie {...config} />
    </Style>
  );
};

const Style = styled.div`
  width: 600px;
  height: 500px;
  h2 {
    text-align: center;
  }
  .all-location-pie {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
      margin: 0 1rem;
      width: 400px;
    }
  @media screen and (max-width: 458px) {
    width: 420px;
  height: 450px;
    .all-location-pie {
      margin: 0 1rem;
      width: 400px;
    }
  }
`;
