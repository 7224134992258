import { Steps, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ShowJobcardStyle } from "./JobCard.style";
import ImageViewer from "components/molecules/ImageViewer";
import { PERMISSIONS, USER_ROLES } from "components/common/Constants";
import { roleSelector, useGetClientsQuery } from "features/apis/jobCardApi";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import JobcardPrint from "./JobcardPrint";
import { useGetLocationQuery } from "features/store";

const Jobcard = ({ data, isLoading }) => {
  const componentRef = useRef();
  const [client, setClient] = useState();
  const { isLoading: loading, data: clients } = useGetClientsQuery();
  const { isLoading: loloading, data: place } = useGetLocationQuery();

  const placedt = place?.find((item) => item.id === data?.location);

  const role = useSelector(roleSelector);
  const handlePrint = useReactToPrint({
    copyStyles: true,
    content: () => componentRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => alert("Print success"),
  });

  const showRate = () =>
    [USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.ACCOUNT].includes(
      role
    );
  const showClient = () =>
    [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERVISOR,
      USER_ROLES.PRODUCTION,
      USER_ROLES.FINISHING,
      USER_ROLES.ACCOUNT,
    ].includes(role);

  useEffect(() => {
    if (data && clients?.length > 0) {
      const client = clients.find((client) => client.id === data.client);
      if (
        role === USER_ROLES.ADMIN ||
        role === USER_ROLES.SUPERVISOR ||
        role === USER_ROLES.PRODUCTION ||
        role === USER_ROLES.FINISHING ||
        role === USER_ROLES.ACCOUNT ||
        role === USER_ROLES.CLIENT
      ) {
        setClient(client);
      }
    }
  }, [clients, data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <button
        style={{
          textAlign: "center",
          background: "var(--color-primary)",
          color: "var(--light)",
          padding: ".5rem 1rem",
          border: "none",
          borderRadius: ".2rem",
          fontWeight: "500",
          float: "right",
          margin: "0 1rem",
        }}
        onClick={handlePrint}
      >
        Print Out
      </button>

      <Style ref={componentRef}>
        <div className="created-by">
          Created By:<b>{data.Created_by}</b>, Location:{" "}
          <b>{placedt?.location}</b>
        </div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            left: "50%",
            marginBottom: ".5rem",
          }}
        ></div>
        <div>
          <div className="title">Job card No : {data.id}</div>
          {/* <div className="title">Job In Time : {data?.Job_in_time}</div> */}
          <div className="row">
            <div>
              <label>Date</label>
              <span>{data.Date}</span>
            </div>
            <div>
              <label>Ref. Challan No</label>
              <span>{data.id}</span>
            </div>
          </div>
          <div className="row-qty">
            <div>
              <label>Party Name</label>
              <span>{client?.name}</span>
            </div>
            <div>
              <label>Party Person</label>
              <span>{client?.contact_person}</span>
            </div>
            {data?.rate ? (
              <div>
                <label>Rate</label>
                <span>{data.rate}</span>
              </div>
            ) : null}
          </div>
          <div className="row">
            {showRate() && (
              <div>
                <label>Job Coordinator</label>
                <span>{data.Job_coordinator}</span>
              </div>
            )}
            <div>
              <label>Design Name</label>
              <span>{data.Design_name}</span>
            </div>
          </div>
          <div className="row">
            {data?.Stitches ? (
              <>
                {showClient() && (
                  <div>
                    <label>Stitches</label>
                    <span>{data.Stitches}</span>
                  </div>
                )}
              </>
            ) : null}
            {showRate() && (
              <div>
                <label>Mob</label>
                <span>{data.Mob}</span>
              </div>
            )}
          </div>
          <div className="row-qty">
            <div>
              <label>Total Qty.</label>
              <span>{data.Quantity}</span>
            </div>
            {showClient() && (
              <div>
                <label>Rej Qty</label>
                <span>{data.Rejected_quantity}</span>
              </div>
            )}
            {showClient() && (
              <div>
                <label>Finished Qty</label>
                <span>{data.Finished_quantity}</span>
              </div>
            )}
          </div>
          {/*<div className="row">
            <div>
              <label>Product</label>
              <span>{data.pocket_patti_pen_side}</span>
            </div>
            </div>*/}

          <div className="section">
            <div className="section-title">Product</div>
            {(data.tshirt_front_lc ||
              data.tshirt_front_rc ||
              data.tshirt_front_cc ||
              data.tshirt_back_cc ||
              data.tshirt_front_lc_palla ||
              data.tshirt_front_rc_palla ||
              data.tshirt_left_sleeves ||
              data.tshirt_right_sleeves ||
              data.tshirt_left_sleeves_palla ||
              data.tshirt_right_sleeves_palla ||
              data.tshirt_back ||
              data.tshirt_back_palla ||
              data.tshirt_front_above_pocket) && (
              <div className="sub-section">
                <div className="sub-section-title ">T Shirt</div>
                <div className="row">
                  {data.tshirt_front_lc && (
                    <div>
                      <label>Front LC</label>
                      <span>{data.tshirt_front_lc}</span>
                    </div>
                  )}
                  {data.tshirt_front_rc && (
                    <div>
                      <label>Front RC</label>
                      <span>{data.tshirt_front_rc}</span>
                    </div>
                  )}
                  {data.tshirt_front_cc && (
                    <div>
                      <label>Front CC</label>
                      <span>{data.tshirt_front_cc}</span>
                    </div>
                  )}
                  {data.tshirt_back_cc && (
                    <div>
                      <label>Back CC</label>
                      <span>{data.tshirt_back_cc}</span>
                    </div>
                  )}
                  {data.tshirt_front_lc_palla && (
                    <div>
                      <label>Front LC Palla</label>
                      <span>{data.tshirt_front_lc_palla}</span>
                    </div>
                  )}
                  {data.tshirt_front_rc_palla && (
                    <div>
                      <label>Front RC Palla</label>
                      <span>{data.tshirt_front_rc_palla}</span>
                    </div>
                  )}
                  {data.tshirt_left_sleeves && (
                    <div>
                      <label>Left Sleeves</label>
                      <span>{data.tshirt_left_sleeves}</span>
                    </div>
                  )}
                  {data.tshirt_right_sleeves && (
                    <div>
                      <label>Right Sleeves</label>
                      <span>{data.tshirt_right_sleeves}</span>
                    </div>
                  )}
                  {data.tshirt_left_sleeves_palla && (
                    <div>
                      <label>Left Sleeves Palla</label>
                      <span>{data.tshirt_left_sleeves_palla}</span>
                    </div>
                  )}
                  {data.tshirt_right_sleeves_palla && (
                    <div>
                      <label>Right Sleeves Palla</label>
                      <span>{data.tshirt_right_sleeves_palla}</span>
                    </div>
                  )}
                  {data.tshirt_back && (
                    <div>
                      <label>Back</label>
                      <span>{data.tshirt_back}</span>
                    </div>
                  )}
                  {data.tshirt_back_palla && (
                    <div>
                      <label>Back Palla</label>
                      <span>{data.tshirt_back_palla}</span>
                    </div>
                  )}
                  {data.tshirt_front_above_pocket && (
                    <div>
                      <label>Front Above Pocket</label>
                      <span>{data.tshirt_front_above_pocket}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(data.shirt_front_lc ||
              data.shirt_front_rc ||
              data.shirt_front_cc ||
              data.shirt_back_cc ||
              data.shirt_front_lc_palla ||
              data.shirt_front_rc_palla ||
              data.shirt_left_sleeves ||
              data.shirt_right_sleeves ||
              data.shirt_left_sleeves_palla ||
              data.shirt_right_sleeves_palla ||
              data.shirt_back ||
              data.shirt_back_palla ||
              data.shirt_front_above_pocket ||
              data.shirt_cuff ||
              data.shirt_cuff_palla ||
              data.shirt_collar ||
              data.shirt_collar_palla) && (
              <div className="sub-section">
                <div className="sub-section-title ">Shirt</div>
                <div className="row">
                  {data.shirt_front_lc && (
                    <div>
                      <label>Front LC</label>
                      <span>{data.shirt_front_lc}</span>
                    </div>
                  )}
                  {data.shirt_front_rc && (
                    <div>
                      <label>Front RC</label>
                      <span>{data.shirt_front_rc}</span>
                    </div>
                  )}
                  {data.shirt_front_cc && (
                    <div>
                      <label>Front CC</label>
                      <span>{data.shirt_front_cc}</span>
                    </div>
                  )}
                  {data.shirt_back_cc && (
                    <div>
                      <label>Back CC</label>
                      <span>{data.shirt_back_cc}</span>
                    </div>
                  )}
                  {data.shirt_front_lc_palla && (
                    <div>
                      <label>Front LC Palla</label>
                      <span>{data.shirt_front_lc_palla}</span>
                    </div>
                  )}
                  {data.shirt_front_rc_palla && (
                    <div>
                      <label>Front RC Palla</label>
                      <span>{data.shirt_front_rc_palla}</span>
                    </div>
                  )}
                  {data.shirt_left_sleeves && (
                    <div>
                      <label>Left Sleeves</label>
                      <span>{data.shirt_left_sleeves}</span>
                    </div>
                  )}
                  {data.shirt_right_sleeves && (
                    <div>
                      <label>Right Sleeves</label>
                      <span>{data.shirt_right_sleeves}</span>
                    </div>
                  )}
                  {data.shirt_left_sleeves_palla && (
                    <div>
                      <label>Left Sleeves Palla</label>
                      <span>{data.shirt_left_sleeves_palla}</span>
                    </div>
                  )}
                  {data.shirt_right_sleeves_palla && (
                    <div>
                      <label>Right Sleeves Palla</label>
                      <span>{data.shirt_right_sleeves_palla}</span>
                    </div>
                  )}
                  {data.shirt_back && (
                    <div>
                      <label>Back</label>
                      <span>{data.shirt_back}</span>
                    </div>
                  )}
                  {data.shirt_back_palla && (
                    <div>
                      <label>Back Palla</label>
                      <span>{data.shirt_back_palla}</span>
                    </div>
                  )}
                  {data.shirt_front_above_pocket && (
                    <div>
                      <label>Front Above Pocket</label>
                      <span>{data.shirt_front_above_pocket}</span>
                    </div>
                  )}
                  {data.shirt_cuff && (
                    <div>
                      <label>Cuff</label>
                      <span>{data.shirt_cuff}</span>
                    </div>
                  )}
                  {data.shirt_cuff_palla && (
                    <div>
                      <label>Cuff Palla</label>
                      <span>{data.shirt_cuff_palla}</span>
                    </div>
                  )}
                  {data.shirt_collar && (
                    <div>
                      <label>Collar</label>
                      <span>{data.shirt_collar}</span>
                    </div>
                  )}
                  {data.shirt_collar_palla && (
                    <div>
                      <label>Collar Palla</label>
                      <span>{data.shirt_collar_palla}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(data.cap_front ||
              data.cap_front ||
              data.cap_left_middle ||
              data.cap_right_middle ||
              data.cap_back ||
              data.cap_velcro ||
              data.cap_visor ||
              data.cap_back_left_side ||
              data.cap_back_right_side ||
              data.cap_back_middle_right ||
              data.cap_back_middle_left ||
              data.cap_fornt_left ||
              data.cap_front_right) && (
              <div className="sub-section">
                <div className="sub-section-title ">Cap</div>
                <div className="row">
                  {data.cap_front && (
                    <div>
                      <label>Front</label>
                      <span>{data.cap_front}</span>
                    </div>
                  )}
                  {data.cap_left_middle && (
                    <div>
                      <label>Left Middle</label>
                      <span>{data.cap_left_middle}</span>
                    </div>
                  )}
                  {data.cap_right_middle && (
                    <div>
                      <label>Right Middle</label>
                      <span>{data.cap_right_middle}</span>
                    </div>
                  )}
                  {data.cap_back && (
                    <div>
                      <label>Back</label>
                      <span>{data.cap_back}</span>
                    </div>
                  )}
                  {data.cap_velcro && (
                    <div>
                      <label>Velcro</label>
                      <span>{data.cap_velcro}</span>
                    </div>
                  )}
                  {data.cap_visor && (
                    <div>
                      <label>Visor</label>
                      <span>{data.cap_visor}</span>
                    </div>
                  )}
                  {data.cap_back_left_side && (
                    <div>
                      <label>Back Left Side</label>
                      <span>{data.cap_back_left_side}</span>
                    </div>
                  )}
                  {data.cap_back_right_side && (
                    <div>
                      <label>Back Right Side</label>
                      <span>{data.cap_back_right_side}</span>
                    </div>
                  )}
                  {data.cap_back_middle_right && (
                    <div>
                      <label> Back + middle Right</label>
                      <span>{data.cap_back_middle_right}</span>
                    </div>
                  )}
                  {data.cap_back_middle_left && (
                    <div>
                      <label>Back + Middle Left</label>
                      <span>{data.cap_back_middle_left}</span>
                    </div>
                  )}
                  {data.cap_fornt_left && (
                    <div>
                      <label>Front Left</label>
                      <span>{data.cap_fornt_left}</span>
                    </div>
                  )}
                  {data.cap_front_right && (
                    <div>
                      <label>Front Right</label>
                      <span>{data.cap_front_right}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {data.bag_front && (
              <div className="sub-section">
                <div className="sub-section-title ">Bag</div>
                <div className="row">
                  <div>
                    <label>Bag Front</label>
                    <span>{data.bag_front}</span>
                  </div>
                </div>
              </div>
            )}
            {(data.appron_front_rc ||
              data.appron_right_lc ||
              data.appron_front_center) && (
              <div className="sub-section">
                <div className="sub-section-title ">Apron</div>
                <div className="row">
                  {data.appron_front_rc && (
                    <div>
                      <label>Front LC</label>
                      <span>{data.appron_front_rc}</span>
                    </div>
                  )}
                  {data.appron_right_lc && (
                    <div>
                      <label>Right LC</label>
                      <span>{data.appron_right_lc}</span>
                    </div>
                  )}
                  {data.appron_front_center && (
                    <div>
                      <label>Front Center</label>
                      <span>{data.appron_front_center}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(data.waist_coat_left_sleeves ||
              data.waist_coat_right_sleeves) && (
              <div className="sub-section">
                <div className="sub-section-title ">Waist Coat</div>
                <div className="row">
                  {data.waist_coat_left_sleeves && (
                    <div>
                      <label>Left Sleeves</label>
                      <span>{data.waist_coat_left_sleeves}</span>
                    </div>
                  )}
                  {data.waist_coat_right_sleeves && (
                    <div>
                      <label>Right Sleeves</label>
                      <span>{data.waist_coat_right_sleeves}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(data.pant_left_side || data.pant_right_side) && (
              <div className="sub-section">
                <div className="sub-section-title ">Pant</div>
                <div className="row">
                  {data.pant_left_side && (
                    <div>
                      <label>Left Side</label>
                      <span>{data.pant_left_side}</span>
                    </div>
                  )}
                  {data.pant_right_side && (
                    <div>
                      <label>Right Side</label>
                      <span>{data.pant_right_side}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(data.pocket_patti_center ||
              data.pocket_patti_pen_side ||
              data.pocket_center) && (
              <div className="sub-section">
                <div className="sub-section-title ">Pocket</div>
                <div className="row">
                  {data.pocket_patti_center && (
                    <div>
                      <label>Pocket Patti Center</label>
                      <span>{data.pocket_patti_center}</span>
                    </div>
                  )}
                  {data.pocket_patti_pen_side && (
                    <div>
                      <label>Pocket Patti Pen Side</label>
                      <span>{data.pocket_patti_pen_side}</span>
                    </div>
                  )}
                  {data.pocket_center && (
                    <div>
                      <label>Pocket Center</label>
                      <span>{data.pocket_center}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="section">
            <div className="section-title">Design Size</div>
            <div className="row">
              <div>
                <label>Width</label>
                <span>{data.Design_width}</span>
              </div>
              <div>
                <label>Height</label>
                <span>{data.Design_height}</span>
              </div>
              <div>
                <label>Ring Size</label>
                <span>123</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div>
              <label>Design Color Code</label>
              <span>{data.Design_color_code}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <label>Fusing</label>
              <span>{data.Fusing}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <label>Job in Time</label>
              <span>{data.Job_in_time}</span>
            </div>
            <div>
              <label>Job Out Time</label>
              <span>{data.job_out_field}</span>
            </div>
          </div>
          {!!(data.Rejected_quantity || data.Finished_quantity) && (
            <div className="row">
              <div className="rejected-count">
                <label>Rejected</label>
                <div className="tag">
                  {data.Rejected_quantity && (
                    <Tag color="red">{data.Rejected_quantity}</Tag>
                  )}
                </div>
              </div>
              <div className="finished-count">
                <label>Finished</label>
                <div className="tag">
                  {data.Finished_quantity && (
                    <Tag color="green">{data.Finished_quantity}</Tag>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div>
              <label>Special Instruction</label>
              <span>{data.special_instruction}</span>
            </div>
          </div>
          {data.image && (
            <div className="section">
              <div className="section-title">Image</div>
              <ImageViewer src={data.image} className="image" />
            </div>
          )}
          {data.Admin_or_client_comment && (
            <div
              className={`client-comment ${
                data.is_rejected ? "rejected" : "approved"
              }`}
            >
              <div>
                <label>Client Comment</label>
                <div className="tag">
                  {data.is_rejected && <Tag color="red">Rejected</Tag>}
                  {data.is_approved && <Tag color="green">Approved</Tag>}
                </div>
              </div>
              <span>{data.Admin_or_client_comment}</span>
            </div>
          )}
        </div>
      </Style>
    </>
  );
};

const Style = styled.div`
  max-width: 900px;
  width: 100%;
  padding: 2rem;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  /* &::after {
    content: "";
    display: block;
    border-width: 0px 75px 75px 0px;
    border-style: solid;
    border-color: #d7d7d7 transparent;
    position: absolute;
    top: 0;
    right: 0;
  }
  &::after {
    content: "";
    display: block;
    border-width: 0px 75px 75px 0px;
    border-style: solid;
    border-color: #d7d7d7 #fff;
    box-shadow: -5px 6px 10px rgb(129, 127, 127);
    position: absolute;
    top: 0;
    right: 0;
  } */

  .img {
    width: 100%;
    height: auto;
  }

  .title {
    font-size: 2.2rem;
    padding-bottom: 1rem;
    text-align: center;
    font-weight: 900;
    color: var(--color-primary);
  }

  label {
    font-size: 1rem;
    /* font-weight: 600; */
    /* color: black; */
  }
  span {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }
  .row {
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    margin: 0.4rem 0;
    border-radius: 0.2rem;
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .row-qty {
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem;
    gap: 1rem;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    margin: 0.4rem 0;
    border-radius: 0.2rem;
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
    }
  }

  .section {
    padding: 1rem;
    border: 1px solid grey;
    margin: 1rem 0;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    .section-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .sub-section-title {
      font-size: 1rem;
      font-weight: 500;
    }
    .row {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .sub-section {
    background: white;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .client-comment {
    div {
      display: flex;
      gap: 1rem;
    }

    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;
    &.rejected {
      border: 2px solid rgba(255, 0, 0, 0.7);
    }
    &.approved {
      border: 2px solid #90e590;
    }
  }

  .tag {
    .ant-tag {
      padding: 0.3rem 0.5rem;
      font-size: 0.8rem;
    }
  }
  .rejected-count,
  .finished-count {
    padding: 1rem;
    background: rgba(255, 0, 0, 0.2);
    color: white;
  }

  .finished-count {
    background: rgba(0, 255, 0, 0.2);
  }
`;

export default Jobcard;
