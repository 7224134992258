import { configureStore } from "@reduxjs/toolkit";
import { jobCardApi } from "./apis/jobCardApi";
import userReducer from "./auth/userSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    [jobCardApi.reducerPath]: jobCardApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(jobCardApi.middleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

export {
  useFetchJobcardsQuery,
  useGetJobcardQuery,
  useGetClientsQuery,
  useCreateJobcardMutation,
  useUpdateJobcardMutation,
  useGetLocationQuery,
  useGetDropdownsQuery,
  useGetCardsUnderApprovalQuery,
  useGetCardsByLocationQuery,

  

  useGetUniqueClientQuery,
  useGetUniqueLocationQuery,
  useFetchLocationCountQuery,
  useFetchJobcardStageCountQuery
} from "./apis/jobCardApi";
