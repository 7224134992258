import { Card, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const CountperLocation = ({ locationcards, locloading }) => {
  
  
if(locloading) return <><Spin
indicator={
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
}
/></>

  return (
    <>
      <Style>
        <div className="quantity-section">
          <Row gutter={16}>
            {locationcards?.map((item, index)=><Col
              xs={{
                span: 11,
                offset: 1,
              }}
              lg={{
                span: 4,
                // offset: 1,
              }}>
              <Card title={item.location} bordered={false}>
                {item.count}
              </Card>
            </Col>)}
          </Row>
        </div>
      </Style>
    </> //
  );
};

export default CountperLocation;

const Style = styled.div`
/* display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; */
  .quantity-section {
    background-color: rgb(240, 242, 245);
    padding: 2rem;
    margin: 1rem auto;
  }

  @media screen and (max-width: 458px) {
    .quantity-section {
      padding: 0.5rem;
      margin: 1rem 0;
    }
  }
`;
