import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "components/common/api";
import { ROLES, USER_ROLES } from "components/common/Constants";

const jobCardApi = createApi({
  reducerPath: "jobcards",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}` }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["Card", "Client", "Location", "Dropdowns","Unique-Client","Unique-Location"],
  endpoints(build) {
    return {
      fetchJobcards: build.query({
        query: ({page,extra,array}) => {
          if(extra){

            return {
              url: `/api/?page=${page}${extra}${array?array:""}`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                Accept: "application/json",
              },
              
            };
          }else{
            return {
              url: `/api/?page=${page}${array?array:""}`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                Accept: "application/json",
              },
              
            };
          }
        },
        providesTags: (result = [], error, arg) =>
          result?.results?.length
            ? [...result?.results?.map(({ id }) => ({ type: "Card", id })), "Card"]
            : ["Card"],
      }),
      getJobcard: build.query({
        query: (id) => {
          return {
            url: `/api/detail/${id}`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "content-Type": "application/json",
            },
          };
        },
        providesTags: ["Card"],
      }),
      createJobcard: build.mutation({
        query: (createJobcardData) => {
          const { ...data } = createJobcardData;

          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          return {
            url: `/api/addjobcard`,
            method: "POST",
            body: formdata,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "Card", id: arg.id }],
      }),
      updateJobcard: build.mutation({
        query: (updateJobcardData) => {
          const { id, ...data } = updateJobcardData;
          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          return {
            url: `/api/update/${id}`,
            method: "PATCH",
            body: formdata,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "Card", id: arg.id }],
      }),
      getClients: build.query({
        query: () => {
          return {
            url: `/api/clients`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        providesTags: ["Client"],
      }),
      getUniqueClient: build.query({
        query: () => {
          return {
            url: `/api/unique-client/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        providesTags: ["Unique-Client"],
      }),
      getUniqueLocation: build.query({
        query: () => {
          return {
            url: `/api/unique-location/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        providesTags: ["Unique-Location"],
      }),
      getLocation: build.query({
        query: () => {
          return {
            url: `/api/locationarray`,
            method: "GET",
          };
        },
        providesTags: ["Location"],
      }),
      getCardsUnderApproval: build.query({
        query: () => {
          return {
            url: `/api/approvedcards`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "content-Type": "application/json",
            },
          };
        },
        providesTags: ["Card"],
      }),
      getCardsByLocation: build.query({
        query: () => {
          return {
            url: `/api/getlocation`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "content-Type": "application/json",
            },
          };
        },
        providesTags: ["Card"],
      }),
      fetchLocationCount: build.query({
        query: () => {
          return {
            url: `/api/locationcount/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "content-Type": "application/json",
            },
          };
        },
        providesTags: ["Card"],
      }),
      fetchJobcardStageCount: build.query({
        query: (query) => {
          return {
            url: `/api/jobcardcount/`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "content-Type": "application/json",
            },
            params: { ...query },
          };
        },
        providesTags: ["Card"],
      }),
      getDropdowns: build.query({
        query: () => {
          return {
            url: `/api/array`,
            method: "GET",
          };
        },
        providesTags: ["Dropdowns"],
      }),
    };
  },
});

export const {
  useFetchJobcardsQuery,
  useGetJobcardQuery,
  useGetClientsQuery,
  useCreateJobcardMutation,
  useUpdateJobcardMutation,
  useGetLocationQuery,
  useGetDropdownsQuery,
  useGetCardsUnderApprovalQuery,
  useGetCardsByLocationQuery,
  useGetUniqueClientQuery,
  useGetUniqueLocationQuery,
  useFetchLocationCountQuery,
  useFetchJobcardStageCountQuery
} = jobCardApi;
export { jobCardApi };

export const roleSelector = (state) => state?.user?.user?.role || null;
// export const roleSelector = (state) => USER_ROLES.SUPERVISOR;
