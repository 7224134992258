import { message } from "antd";
import JobCardForm from "components/jobcard/JobcardForm";
import Loader from "components/loaders/Loader";
import {
  useFetchJobcardsQuery,
  useGetJobcardQuery,
  useUpdateJobcardMutation,
} from "features/store";
import React from "react";
import { useParams } from "react-router-dom";

const UpdateJobcard = () => {
  const { id } = useParams();

  const { isLoading, isError, data: jobcard } = useGetJobcardQuery(id);

  if (isError) message.error("Failed to fetch job card details");

  if (isLoading) return <Loader />;

  return <JobCardForm formValues={jobcard} />;
};

export default UpdateJobcard;
