import Loader from "components/loaders/Loader";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function Auth({ children }) {
  const { user, userToken, loading, checkAuthLoading } = useSelector(
    (state) => state.user
  );

  if (checkAuthLoading)
    return (
      <h1 style={{ margin: "2rem", textAlign: "center" }}>
        <Loader />
      </h1>
    );
  if (loading) return <Loader />;

  if (!user || !userToken) {
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}

export default Auth;
