const { default: styled } = require("styled-components");

export const ShowJobcardStyle = styled.div`
  text-transform: uppercase;
  .pag {
    background-color: black;
    color: #c3c3c3;
    max-width: 1200px;
    margin: 0 auto;

    min-height: 100px;
    container-type: inline-size;
    text-transform: uppercase;
  }

  .layout {
    min-height: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    main {
      min-height: 100px;
      ul {
        /* border: 5px dashed red; */
        li {
          display: flex;
          /* border: 2px dashed blue; */
          list-style-type: none;
          font-size: 1.5rem;
          padding: 0.4rem 0;
          span {
            font-weight: 400;
            border-bottom: 1px solid black;
            border-width: thin;
            padding-left: 5px;
            display: inline-block;
            flex-grow: 4;
          }
          b {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .layout_two {
    min-height: 100px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    main {
      min-height: 100px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        li {
          display: flex;
          /* border: 2px dashed blue; */
          list-style-type: none;
          font-size: 1.5rem;
          padding: 0.4rem 0;
          span {
            font-weight: 400;
            border-bottom: 1px solid black;
            border-width: thin;
            padding-left: 5px;
            display: inline-block;
            flex-grow: 4;
          }
          b {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .layout_three {
    min-height: 100px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    main {
      min-height: 100px;
      .fusing_fields {
        display: flex;
        flex-direction: row;
      }
      .job_time_field {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        li {
          display: flex;
          /* border: 2px dashed blue; */
          list-style-type: none;
          font-size: 1.5rem;
          padding: 0.4rem 0;
          width: 100%;
          span {
            font-weight: 400;
            border-bottom: 1px solid white;
            border-width: thin;
            padding-left: 5px;
            display: inline-block;
            flex-grow: 4;
          }
          b {
            margin-right: 5px;
          }
        }
      }
    }
  }

  @container (min-width: 900px) {
    .layout {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
  }
`;
