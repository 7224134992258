import React, { useEffect, useRef, useState } from "react";
import { roleSelector, useFetchJobcardsQuery, useGetJobcardQuery, useUpdateJobcardMutation } from "features/apis/jobCardApi";
import { json, useNavigate, useParams } from "react-router-dom";
import Skeleton from "components/common/Skeleton";
import Jobcard from "components/jobcard/Jobcard";
import { useSelector } from "react-redux";
import { STAGES, USER_ROLES } from "components/common/Constants";
import FinishingForm from "components/molecules/FinishingForm";
import ClientFeedbackForm from "components/molecules/ClientForm";
import styled from "styled-components";
import { Button, Steps } from "antd";
import JobcardStep from "components/molecules/JobcardStep";
import Loader from "components/loaders/Loader";
import JobcardPrint from "components/jobcard/JobcardPrint";
import { getFormalName, getNextStage, getPreviousStage } from "components/common/Services";

const JobcardDetails = () => {
  const { id } = useParams();
  const role = useSelector(roleSelector);
  const navigate = useNavigate();
  


  const jobcardDetail = useGetJobcardQuery(id);

  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();

  const { isLoading: isUpdating, isSuccess } = updateResponseInfo;
  console.log('isSuccess: ', isSuccess);

  const { data , error, isLoading, refetch } = jobcardDetail;
  if(isSuccess){
    return navigate('/jobcards')
  }
  // useEffect(() => {
  //   refetch();
  // }, [isSuccess]);
  
  let RenderJobcard;

  if (isLoading) {
    RenderJobcard = <Skeleton times={6} />;
  } else if (error) {
    RenderJobcard = <div>Error fetching data</div>;
  } else {
    RenderJobcard = <Jobcard data={data} isLoading={isLoading} />;
  }

  if (!data) return <Loader />;

  return (
    <Style>
      <div className="top-container">
        <JobcardStep current_stage={data.current_stage} />
      
        <div> {RenderJobcard} </div>
      </div>
      <div className="client-feedback">
        {role === USER_ROLES.FINISHING && <FinishingForm id={id} client={data.client} />}
        {[USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.CLIENT].includes(role) &&
          data.current_stage === STAGES.APPROVAL && (
            <ClientFeedbackForm id={id} client={data.client} />
          )}
      </div>
      <div className="btns">
      
      {role === USER_ROLES.FINISHING ? null : <div>
      <Button
          onClick={(e) => {
            e.stopPropagation();
            updateJobcard({
              id: data.id,
              client: data.client,
              current_stage: getPreviousStage(data.current_stage),
            });
          }}
          disabled={getPreviousStage(data.current_stage) === "-"}
          loading={isUpdating}
        >
          {data.current_stage === STAGES.COMPLETED
            ? "Completed"
            : `Send back to ${getFormalName(
                getPreviousStage(data.current_stage)
              )}`}
        </Button>

        <Button
          onClick={(e) => {
            updateJobcard({
              id: data.id,
              client: data.client,
              current_stage: getNextStage(data.current_stage),
            });
          }}
          disabled={getNextStage(data.current_stage) === "-"}
          loading={isUpdating}
          >
          {data.current_stage === STAGES.COMPLETED
            ? "Completed"
            : `${
                data.current_stage === STAGES.ACCOUNT
                ? "Mark as"
                  : "Forward to"
                }  ${getFormalName(getNextStage(data.current_stage))}`}
        </Button>
        </div>}
      </div>
    </Style>
  );
};

const Style = styled.div`
  padding: 4rem 0;
  .top-container {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .client-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
  }
`;

export default JobcardDetails;
