import React from "react";
import { Link } from "react-router-dom";
import { FooterStyles } from "./Footer.style";

const Footer = () => {
  const year = (new Date().getFullYear())
  return (
    <>
      <FooterStyles>
        <section className="contact-area" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="contact-content text-center">
                  <div className="footer-content-i">
                    <Link to="#">
                      <h1>Kesh Fashion</h1>
                    </Link>
                    <p className="contact-quote">
                      "If you don't care your customer, Somebody else will"
                    </p>
                  </div>
                  <div className="footer-content-i">
                    <div className="hr"></div>
                    <h6>Kesh Fashion House, Pune.</h6>
                    <h6>+91 2345 6789 12</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <p>Copyright &copy; {year} Kesh Fashion, All Rights Reserved.</p>
          <Link style={{ padding: "1rem" }} to="/privacy">
            Privacy Policy
          </Link>
        </footer>
      </FooterStyles>
    </>
  );
};

export default Footer;
