import { STAGES, STAGES_LIST } from "./Constants";

export const getNextStage = (stage) => {
  if ([STAGES.APPROVAL, STAGES.COMPLETED].includes(stage)) return "-";

  let isValid = STAGES_LIST.includes(stage);
  if (!isValid) return "-";
  let index = STAGES_LIST.indexOf(stage);
  if (index === STAGES_LIST.length - 1) return "-";
  return STAGES_LIST[index + 1];
};

export const getPreviousStage = (stage) => {
  if (
    [
      STAGES.APPROVAL,
      STAGES.PRODUCTION,
      STAGES.FINISHING,
      STAGES.ACCOUNT,
      STAGES.COMPLETED,
    ].includes(stage)
  )
    return "-";

  let isValid = STAGES_LIST.includes(stage);
  if (!isValid) return "-";
  let index = STAGES_LIST.indexOf(stage);
  if (index === 0) return "-";
  return STAGES_LIST[index - 1];
};

export const getFormalName = (stage) => {
  switch (stage) {
    case STAGES.CREATION:
      return "Creation";
    case STAGES.APPROVAL:
      return "Approval";
    case STAGES.PRODUCTION:
      return "Production";
    case STAGES.FINISHING:
      return "Finishing";
    case STAGES.ACCOUNT:
      return "Account";
    case STAGES.COMPLETED:
      return "Completed";
    default:
      return "-";
  }
};
