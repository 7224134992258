import { Chart as ChartJs, ArcElement, Legend, Title, Tooltip } from "chart.js";
import { STAGES_LIST } from "components/common/Constants";
import { getFormalName } from "components/common/Services";
import { getJobcardsForStage } from "helpers/jobcardHelper";
import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
ChartJs.register(Tooltip, Title, ArcElement, Legend);

const CountChrt = ({ jobcards }) => {
  const cards = STAGES_LIST.map((stage) => ({
    name: stage.toUpperCase(),
    value: getJobcardsForStage(jobcards, stage).length || 0,
  }));
console.log(jobcards)
  const valueData = cards.map((item) => item.value)
  const nameData = cards.map((item) => item.name)


  return (
    <div style={{ width: "500px", height: "auto" }}>
      <Pie
        width={300}
        height={200}
        data={{
          labels: nameData || valueData, 
          datasets: [
            {
              label: '',
              data: valueData,
              backgroundColor: [
                "rgba(0, 0, 255, 0.9)",
                "rgba(54, 162, 235, 0.9)",
                "rgba(255, 206, 86, 0.9)",
                "rgba(75, 192, 192, 0.9)",
                "rgba(153, 102, 255, 0.9)",
                "rgba(255, 159, 64, 0.9)",
              ],
              borderColor: [
                "rgba(0, 0, 255, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderWidth: 1,
              hoverOffset: 10,
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            title: {
              font: { size: 30 },
              text: "Job Cards",
              display: true,
            },
            legend: {
              labels: {
                font: { size: 15 },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default CountChrt;
