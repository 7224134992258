
import { Select } from "antd";
import {
  arrayToQueryParam,
  queryParamToArray,
  queryParamToArray2,
  stageArray,
} from "components/common/Constants";
import { useGetClientsQuery, useGetLocationQuery } from "features/store";
import {
  AllPageTo1,
  ClientArray,
  LocationArray,
  StageArray,
} from "features/thunks/FetchUsers";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const StageFilter = () => {
  const dispatch = useDispatch();
  const { stage_array } = useSelector((state) => state.user);
  const [selectedItems, setSelectedItems] = useState(
    queryParamToArray2(stage_array)
  );
  const handleselect = (data) => {
    const queryString = arrayToQueryParam("current_stage", data);
    dispatch(AllPageTo1());
    dispatch(StageArray(queryString));
    setSelectedItems(data);
    console.log(data);
    console.log(queryString);
  };

  return (
    <div className="filter-row">
        <label>Stage</label>

      <Select
        mode="multiple"
        placeholder="Select Stage"
        value={selectedItems}
        onChange={handleselect}
        style={{
            minWidth: '200px',


          margin: "0px 10px",
        }}
        options={stageArray?.map((item) => ({
          value: item,
          label: item,
        }))}
      />
    </div>
  );
};

export default StageFilter;
