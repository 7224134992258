import React from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Statistic,
  Table,
  Tag,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ScissorOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  getCardsApprovedByClients,
  getCardsForApproval,
  getCardsInAccount,
  getCardsInCreation,
  getCardsInFinishing,
  getCardsInProduction,
  getCardsRejectedByClients,
  getCompletedJobCards,
} from "helpers/jobcardHelper";
import styled from "styled-components";

const Stats = ({ cards }) => {
  const countStageQuantity = (stage) => {
    let quantity = 0;
    const stageCards = cards?.filter((card) => card.current_stage === stage);
    stageCards?.map((card) => {
      quantity += parseInt(card.Quantity || 0);
    });
    return quantity;
  };

  const filteredItems = cards?.filter((item) => {
    return item.current_stage !== "completed";
  });

  const countQuant = filteredItems?.reduce((totalQuantity, filteredItem) => {
    return filteredItem.Quantity + totalQuantity;
  }, 0);

  return (
    <>
      <Style>
        <div className="count-card-section">
          <Row className="card-counts" gutter={10}>
            <Col>
              <Card>
                <Statistic
                  title="Creation"
                  value={getCardsInCreation(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                  prefix={<ScissorOutlined />}
                />
                <span>Qty: {countStageQuantity("creation")}</span>
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="For Client Approval"
                  value={getCardsForApproval(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                />
                <span>Qty: {countStageQuantity("approval")}</span>
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="Approved By Clients"
                  value={getCardsApprovedByClients(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                  // prefix={<CloseCircleOutlined />}
                />
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="Rejected By Clients"
                  value={getCardsRejectedByClients(cards)?.length}
                  valueStyle={{
                    color: "red",
                  }}
                  prefix={<CloseCircleOutlined />}
                />
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="Production"
                  value={getCardsInProduction(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                  // prefix={<ScissorOutlined />}
                />
                <span>Qty: {countStageQuantity("production")}</span>
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="Finishing"
                  value={getCardsInFinishing(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                />
                <span>Qty: {countStageQuantity("finishing")}</span>
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="Account"
                  value={getCardsInAccount(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                  // prefix={<ScissorOutlined />}
                />
                <span>Qty: {countStageQuantity("account")}</span>
              </Card>
            </Col>
            <Col>
              <Card>
                <Statistic
                  title="Completed"
                  value={getCompletedJobCards(cards)?.length}
                  valueStyle={{
                    color: "green",
                  }}
                  prefix={<CheckCircleOutlined />}
                />
                <span>Qty: {countStageQuantity("completed")}</span>
              </Card>
            </Col>

            <Col>
              <Card>
                <Statistic
                  title="Total Jobcards"
                  value={cards?.length}
                  valueStyle={{
                    color: "green",
                  }}
                  // prefix={<CheckCircleFilled />}
                />
                <span>Qty: {countQuant}</span>
              </Card>
            </Col>
          </Row>
        </div>
      </Style>
    </> //
  );
};

export default Stats;

const Style = styled.div`
  span {
    font-size: 14px;
    color: green;
  }
`;
