import { Button, Form, InputNumber, message } from "antd";
import { STAGES } from "components/common/Constants";
import Loader from "components/loaders/Loader";
import { useUpdateJobcardMutation } from "features/store";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function FinishingForm({ id, client }) {
  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();

  const navigate = useNavigate();

  const {
    isError: failedToUpdate,
    isSuccess: updateSuccess,
    isLoading: updating,
  } = updateResponseInfo;

  if (failedToUpdate) message.error("Failed to update job card");

  if (updateSuccess) message.success("Jobcard updated successfully!");

  const handleSubmit = async (values) => {
    await updateJobcard({
      id,
      client,
      ...values,
      current_stage: STAGES.ACCOUNT,
    });
    navigate("/jobcards");
  };

  return (
    <Style>
      {updating && <Loader />}
      <Form onFinish={handleSubmit}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
          label="Rejected Quantity"
          name="Rejected_quantity"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            // style={{ padding: ".5rem 2rem", fontSize: "1rem" }}
            htmlType="submit"
            size="large"
          >
            {"Forward to Account"}
          </Button>
        </Form.Item>
      </Form>
    </Style>
  );
}

const Style = styled.div`
  padding: 2rem;
  display: grid !important;
  place-items: center !important;
`;

export default FinishingForm;
